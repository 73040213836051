<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="마신다">마신다</b-form-radio>
              <b-form-radio value="안마신다">안마신다</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '마신다' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[0] }}</label>
            <b-form-radio-group v-model="q.answer[1].data[0]">
              <b-form-radio value="월 1-2회">월 1-2회</b-form-radio>
              <b-form-radio value="월 3-4회">월 3-4회</b-form-radio>
              <b-form-radio value="주 1-2회">주 1-2회</b-form-radio>
              <b-form-radio value="그 이상">그 이상</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row" :class="q.answer[0].data !== '마신다' ? 'disabled' : ''">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[1] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[1]" />
          </b-col>
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label[2] }}</label>
            <b-form-input type="text" v-model="q.answer[1].data[2]" />
          </b-col>
        </b-row>
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <p class="text-danger">* 소주 1병(7.5잔) = 맥주 2병 = 막걸리 1병 = 양주 0.25병 = 와인 1.5병</p>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q22',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '음주',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '음주',
        answer: [
          {
            data: '',
          },
          {
            label: ['빈도', '술 종류', '술 량'],
            data: ['', '', ''],
          },
        ],
      };
    }
    if (this.q.answer[0].data === '안마신다') {
      // '안함' 선택시 '예' 하위의 질문 답변 초기화
      while (this.q.answer[1].data.length) {
        this.q.answer[1].data.pop();
      }
    }
  },
};
</script>